<template>
	<div class="card card-custom card-stretch gutter-b" ref="idirectGraphs">
		<div class="card-header border-0" v-if="name">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }}</h3>
			<div class="d-flex align-items-center">
				<div
					v-if="(showcirmir && currentGraph === 'traffic') || currentGraph === 'maxtraffic'"
					class="align-self-center"
				>
					<b-form-checkbox switch @input="toggleCirMir" v-model="cirmir">
						Show CIR/MIR</b-form-checkbox
					>
				</div>
				<div class="ml-3">
					<b-button variant="outline-primary" size="sm" @click="generateCSV">Download CSV</b-button>
				</div>
			</div>
		</div>
		<div class="card-body px-3 pb-3 pt-0">
			<div v-if="error" class="pt-5">
				<b-alert show variant="warning" class="m-0">
					<span class="svg-icon-danger">
						<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
					</span>
					There was an error retrieving data for this device
				</b-alert>
			</div>
			<b-tabs v-else :small="smallTabs">
				<template v-for="(graph, name) in visibleGraphs">
					<b-tab
						:title="graph.tabname"
						:key="graph.tabname"
						no-body
						lazy
						@click="setData(graph, name)"
					>
						<dygraphs
							v-if="loaded"
							:data="dyData"
							:options="dyOpts"
							:annotations="dyAnnotations"
							:formatLegend="true"
						/>
					</b-tab>
				</template>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { KMG, MODCODS, downloadCSV } from '@/helpers';

const date = new Date();
const offset = date.getTimezoneOffset() * 60000;

const constructData = (graphData) => {
	return graphData[0].map((data, index) => {
		let row = [new Date(data[0] + offset), data[1]];
		for (let i = 1; i < graphData.length; i++) {
			row.push(graphData[i][index][1]);
		}
		return row;
	});
};

const constructTrafficData = (graphData, lte4g, rates) => {
	return graphData[0].map((data, index) => {
		let row = [new Date(data[0] + offset), data[1]];
		for (let i = 1; i < graphData.length; i++) {
			row.push(graphData[i][index][1]);
		}
		if (lte4g) {
			if (lte4g[data[0] / 1000]) {
				row.push(lte4g[data[0] / 1000].ind, lte4g[data[0] / 1000].out);
			} else {
				row.push(null, null);
			}
		}
		if (rates) {
			row.push(rates.DownCIR * 1000, rates.UpCIR * 1000, rates.DownMIR * 1000, rates.UpMIR * 1000);
		}
		return row;
	});
};

const constructIcmpData = (graphData, cols, ipsla) => {
	let nulls = Array(cols.length).fill(null);
	return graphData[0].map((data, index) => {
		let row = [new Date(data[0] + offset), data[1]];
		for (let i = 1; i < graphData.length; i++) {
			row.push(graphData[i][index][1]);
		}
		if (cols && ipsla) {
			if (ipsla[data[0] / 1000]) {
				cols.forEach((col) => {
					row.push(ipsla[data[0] / 1000][col]);
				});
			} else {
				row = row.concat(nulls);
			}
		}
		return row;
	});
};

const opts = {
	traffic: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return KMG(y) + 'bps';
				},
			},
		},
	},
	sattraffic: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return KMG(y) + 'bps';
				},
			},
		},
	},
	icmp: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + ' ms';
				},
			},
		},
	},
	snr: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + 'dB';
				},
			},
		},
	},
	power: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + 'dBm';
				},
			},
		},
	},
	temp: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + '&deg;C';
				},
			},
		},
	},
	symoff: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + '&deg;';
				},
			},
		},
	},
	uptime: {
		axes: {
			y: {
				valueFormatter: function (y) {
					return y + 'hours';
				},
			},
		},
	},
	modcod: {
		axisLabelWidth: 60,
		axes: {
			y: {
				ticker: function (min, max, pixels, opts, dygraph, vals) {
					return MODCODS.map((m, i) => {
						if (!m) return null;
						return { v: i, label: m.name };
					});
				},
				valueFormatter: function (i) {
					return MODCODS[i].name;
				},
			},
		},
	},
};

const constructAnnotations = (annotations) => {
	const anOptions = {
		height: 25,
		width: 25,
		icon: '/media/svg/custom/beamswitch.svg',
		cssClass: 'beamswitch-annotation',
	};
	let a = annotations.map((a) => {
		return {
			...a,
			x: new Date(a.x + offset).getTime(),
			...anOptions,
		};
	});

	return a;
};

export default {
	name: 'IdirectMaritimeGraphs',
	components: {
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	props: {
		name: {
			required: true,
		},
		graphs: {
			required: true,
		},
		properties: {
			required: false,
		},
		rates: {
			required: false,
		},
	},
	data() {
		return {
			loaded: false,
			dyData: [],
			dyOpts: {},
			dyAnnotations: null,
			showcirmir: false,
			cirmir: false,
			error: false,
			currentGraph: null,
			lte4g: null,
			swipsla: null,
		};
	},
	computed: {
		visibleGraphs() {
			let g = {};
			if (!this.properties) {
				for (let graph in this.graphs) {
					if (graph !== 'iptraffic' && graph !== 'errors') {
						g[graph] = this.graphs[graph];
					}
				}
			} else {
				for (let graph in this.graphs) {
					if (graph === 'maxtraffic') continue;
					if (this.properties[graph]) g[graph] = this.graphs[graph];
				}
			}
			return g;
		},
		smallTabs() {
			let width = 0;
			if (this.loaded) {
				width = this.$refs.idirectGraphs.clientWidth;
			}
			return Object.keys(this.visibleGraphs).length > 8 && width > 900;
		},
	},
	methods: {
		async validate() {
			if (this.graphs === undefined || Array.isArray(this.graphs)) {
				this.error = true;
				return;
			}
			if (this.properties?.lte4gint) {
				let resp = await this.$http.get(`/solarwinds/${this.properties.lte4gint}`);
				this.lte4g = resp.data.data;
			}
			if (
				this.properties?.ipslaonlatencytab &&
				this.properties?.ipslaonlatencytabvals &&
				this.properties?.ipslasource
			) {
				//Do all the checks for ipsla here so if data is fetched it should be rendered
				this.$http.get(`/swipsla/${this.properties.ipslasource.DeviceId}`).then((resp) => {
					this.swipsla = resp.data.data;
				});
			}

			let firstGraph = Object.keys(this.visibleGraphs)[0];
			if (firstGraph) {
				this.error = false;
				this.setData(this.graphs[firstGraph], firstGraph);
			} else {
				this.error = true;
			}
			this.loaded = true;
		},
		setData(graph, name) {
			this.currentGraph = name;
			let data = graph.stats.map((s) => s.data);
			let constructedOpts = {};

			//Traffic/MaxTraffic and ICMP graphs can take additional data from solarwinds queries or static lines based on widget properties
			//so they need additional options conditionally applied with that data
			//All other graphs are constructed with the simple function called in default

			switch (name) {
				case 'traffic':
				case 'sattraffic':
				case 'maxtraffic':
					constructedOpts = {
						visibility: new Array(graph.opts.labels.length - 1).fill(true),
						labels: [...graph.opts.labels],
					};
					if (this.lte4g) {
						constructedOpts.labels.push('4G Downstream', '4G Upstream');
						constructedOpts.colors = [
							'#0000FF',
							'#006600',
							'#CC0099',
							'#FF9900',
							'#FF0000',
							'#000000',
						];
						constructedOpts.visibility.push(true, true);
						constructedOpts.connectSeparatedPoints = true;
					}
					if (this.properties?.showcirmir) {
						if (this.rates) {
							constructedOpts.labels.push('Down CIR', 'Up CIR', 'Down MIR', 'Up MIR');
							constructedOpts.colors = [
								'#0000FF',
								'#006600',
								'#CC0099',
								'#FF9900',
								'#FF0000',
								'#000000',
								'#1669d9',
								'#37c5bd',
								'#663300',
								'#FF00FF',
							];
							constructedOpts.visibility.push(this.cirmir, this.cirmir, this.cirmir, this.cirmir);
							this.showcirmir = true;
						}
					}
					this.dyData = constructTrafficData(
						data,
						this.lte4g?.data,
						this.properties?.showcirmir && this.rates
					);
					break;

				case 'icmp':
					if (this.swipsla) {
						let icmpVals = {
							rtt: { label: 'SLA RTT', key: 'rtt' },
							jitter: { label: 'Jitter', key: 'jitter' },
							lossDS: { label: 'Loss DS', key: 'loss_DS' },
							lossSD: { label: 'Loss SD', key: 'loss_SD' },
						};
						constructedOpts = {
							labels: ['Timestamp', 'iDirect RTT'],
							valueRange: null,
							fillGraph: false,
							connectSeparatedPoints: true,
							axes: {
								y: {
									valueFormatter: function (y, opts, series) {
										if (series.includes('Jitter') || series.includes('RTT')) return y + 'ms';
										else return y + '%';
									},
								},
							},
						};
						if (this.properties.icmpthreshold && this.properties.icmpthreshold != 0) {
							constructedOpts.underlayCallback = (ctx, area, g) => {
								let HighCoords = g.toDomCoords(0, this.properties.icmpthreshold);
								let high = HighCoords[1];
								ctx.beginPath();
								ctx.setLineDash([5, 15]);
								ctx.moveTo(0, high);
								ctx.lineTo(10000, high);
								ctx.strokeStyle = 'red';
								ctx.stroke();
								ctx.setLineDash([]);
							};
						}

						let cols = [];
						for (let col in this.properties.ipslaonlatencytabvals) {
							if (this.properties.ipslaonlatencytabvals[col]) {
								cols.push(icmpVals[col].key);
								constructedOpts.labels.push(icmpVals[col].label);
							}
						}
						this.dyData = constructIcmpData(data, cols, this.swipsla.data);
					} else {
						this.dyData = constructData(data);
					}
					break;
				case 'snr':
					if (
						(this.properties && this.properties.snrthresholds &&
							this.properties.snrthresholds.down &&
							this.properties.snrthresholds.down != 0) ||
						(this.properties && this.properties.snrthresholds &&
							this.properties.snrthresholds.up &&
							this.properties.snrthresholds.up != 0)
					) {
						let vm = this;
						constructedOpts = {
							labels: ['Timestamp', 'Downstream', 'Upstream'],
							valueRange: null,
							fillGraph: false,
							connectSeparatedPoints: true,
							axes: {
								y: {
									valueFormatter: function (y, opts, series) {
										if (
											vm.properties.snrthresholds &&
											vm.properties.snrthresholds.down &&
											vm.properties.snrthresholds.down != 0 &&
											series.includes('Down')
										) {
											return y + 'dB <span style="color:#ff0000">(Threshold: ' + vm.properties.snrthresholds.down + 'dB)</span>';
										} else if (
											vm.properties.snrthresholds &&
											vm.properties.snrthresholds.up &&
											vm.properties.snrthresholds.up != 0 &&
											series.includes('Up')
										) {
											return y + 'dB <span style="color: #eb7d78">(Threshold: ' + vm.properties.snrthresholds.up + 'dB)</span>';
										} else return y + 'dB';
									},
								},
							},
						};
						constructedOpts.underlayCallback = (ctx, area, g) => {
							if (
								this.properties.snrthresholds &&
								this.properties.snrthresholds.down &&
								this.properties.snrthresholds.down != 0
							) {
								let HighCoords = g.toDomCoords(0, this.properties.snrthresholds.down);
								let high = HighCoords[1];
								ctx.beginPath();
								ctx.setLineDash([5, 15]);
								ctx.moveTo(0, high);
								ctx.lineTo(10000, high);
								ctx.strokeStyle = 'red';
								ctx.stroke();
								ctx.setLineDash([]);
							}

							if (
								this.properties.snrthresholds &&
								this.properties.snrthresholds.up &&
								this.properties.snrthresholds.up != 0
							) {
								let HighCoords = g.toDomCoords(0, this.properties.snrthresholds.up);
								let high = HighCoords[1];
								ctx.beginPath();
								ctx.setLineDash([5, 15]);
								ctx.moveTo(0, high);
								ctx.lineTo(10000, high);
								ctx.strokeStyle = '#eb7d78';
								ctx.stroke();
								ctx.setLineDash([]);
							}
						};
					}
					this.dyData = constructData(data);
					break;
				default:
					this.dyData = constructData(data);
			}
			if (graph.opts.annotations) {
				this.dyAnnotations = constructAnnotations(graph.opts.annotations);
			}
			let { ['annotations']: undefined, ...options } = graph.opts;
			this.dyOpts = { ...options, ...opts[name], ...constructedOpts };
		},
		toggleCirMir() {
			this.dyOpts.visibility.splice(-4, 4, this.cirmir, this.cirmir, this.cirmir, this.cirmir);
		},
		generateCSV() {
			let columns = [];
			let csvData = {};
			for (let graph in this.visibleGraphs) {
				this.visibleGraphs[graph].stats.forEach((col) => {
					let label =
						col.label.match(/(Upstream)|(Downstream)|(hours)/) !== null
							? `${this.visibleGraphs[graph].tabname} - ${col.label}`
							: col.label;
					columns.push(label);
					col.data.forEach((row) => {
						if (csvData[row[0]] == undefined) csvData[row[0]] = {};
						if (label === 'MODCOD') {
							if (MODCODS[row[1]] !== undefined) {
								csvData[row[0]][label] = MODCODS[row[1]].name;
							}
						} else {
							csvData[row[0]][label] = row[1];
						}
					});
				});
			}
			let csv = 'Timestamp,' + columns.join(',') + '\n';
			for (let timestamp in csvData) {
				let timestring = new Date(+timestamp).toISOString().split('T');
				csv += timestring[0] + ' ' + timestring[1].substring(0, 8) + ',';
				columns.forEach((col) => {
					csv +=
						csvData[timestamp][col] !== undefined && csvData[timestamp][col] !== null
							? csvData[timestamp][col] + ','
							: ',';
				});
				csv = csv.substring(0, csv.length - 1);
				csv += '\n';
			}
			return downloadCSV(
				csv,
				this.name + ' - iDirect Stats - ' + new Date().toISOString().split('T')[0] + '.csv'
			);
		},
	},
	mounted() {
		this.validate();
	},
	watch: {
		graphs: {
			handler: function (obj, oldObj) {
				this.validate();
			},
		},
	},
};
</script>

<style></style>
